import { createContext, useContext } from 'react'

export type MetaContextType = {
  nav: {
    about: string
    team: string
    portfolio: string
    services: string
    news: string
    contact_us: string
    weibo: string
    jobs: string
    wechat: string
    address: string
    address1: string
    address2: string
    copyright: string
  }
  contact: {
    title: string
    description: string
    bp: string
    bp_email: string
    hr: string
    hr_email: string
  }
}

const defaultMetaContext = {
  nav: {
    about: 'About',
    team: 'Team',
    portfolio: 'Portfolio',
    services: 'Services',
    news: 'News',
    contact_us: 'Contact Us',
    weibo: 'Weibo',
    jobs: 'Jobs',
    wechat: 'Wechat',
    address: 'Address',
    address1: 'Room 1502, Nuojin Office Building',
    address2: 'No.2 jiangtai Road, Chaoyang District, Beijing',
    copyright: 'Chuxin Investment Management Limited',
  },
  contact: {
    title: '做陪伴创业者最早的投资机构',
    description:
      '初心创投是积极布局新兴业态的早期股权投资基金，专注于企业级软件、科技创新等领域的投资。我们致力于捕捉因科技赋能而改变行业格局和通过软件帮助企业降本增效、优化运营的优秀企业，进行投资布局。欢迎留下你的联系方式，与我们取得联系。',
    bp: '投递商业计划书',
    bp_email: 'bp@monadventures.com',
    hr: '投递简历，加入初心',
    hr_email: 'hr@monadventures.com',
  },
}

export const MetaContext = createContext<MetaContextType>(defaultMetaContext)

export const useMeta = () => useContext(MetaContext)
