import i18next from 'i18next'
import { useContext } from 'react'
import {
  I18nContext,
  useTranslation as useTranslationI18next,
} from 'react-i18next'
import { resources } from './locales'

export enum LANGUAGES {
  CHINESE = 'zh',
  ENGLISH = 'en',
}

export function createI18next(lng: string) {
  const i18n = i18next.createInstance()

  i18n.init({
    lng,
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  })

  return i18n
}

export function useI18n() {
  const { i18n } = useContext(I18nContext)

  return {
    ...i18n,
    isEnglish: i18n.language === LANGUAGES.ENGLISH,
    isChinese: i18n.language === LANGUAGES.CHINESE,
  }
}

export function useTranslation<
  T extends { zh?: { [index: string]: any }; en?: { [index: string]: any } }
>(resources: T) {
  const ns = 'translation'
  const i18n = useI18n()

  i18n
    .addResources(LANGUAGES.CHINESE, ns, resources.zh || {})
    .addResources(LANGUAGES.ENGLISH, ns, resources.en || {})

  return useTranslationI18next(ns)
}
