import browserLang from 'browser-lang'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import config from '../config'

export function getPageLanguage(): string {
  if (!isBrowser()) return 'en'

  const detected =
    localStorage.getItem('language') ||
    browserLang({
      languages: ['en', 'zh'],
      fallback: 'en',
    }) ||
    'en'

  return detected
}

export const isBrowser = () =>
  typeof window !== 'undefined' &&
  typeof document !== 'undefined' &&
  typeof navigator !== 'undefined'

export function getLocalizationId(item: any): boolean | number {
  return item.attributes?.localizations?.data?.[0]?.id
}

export function handleImgUrl(url = '') {
  if (url.startsWith('http')) {
    return url
  }
  return `${config.imageUrl}${url}`
}

export function resolveServerImg(img: ServerImageData): IGatsbyImageData {
  return {
    layout: 'constrained',
    width: img.data.attributes.width,
    height: img.data.attributes.height,
    images: {
      sources: [
        {
          srcSet: handleImgUrl(img.data.attributes.url),
          type: 'medium',
        },
      ],
      fallback: {
        src: handleImgUrl(img.data.attributes.url),
      },
    },
  }
}

export function formatTime(t: string) {
  if (typeof t !== 'string') {
    return t
  }
  const tIndex = t.indexOf('T')
  let s = t
  if (tIndex > -1) {
    s = t.slice(0, tIndex)
  }
  return s.replaceAll('-', '.')
}
