exports.components = {
  "component---src-templates-404-ts": () => import("./../../../src/templates/404.ts" /* webpackChunkName: "component---src-templates-404-ts" */),
  "component---src-templates-about-ts": () => import("./../../../src/templates/about.ts" /* webpackChunkName: "component---src-templates-about-ts" */),
  "component---src-templates-article-ts": () => import("./../../../src/templates/article.ts" /* webpackChunkName: "component---src-templates-article-ts" */),
  "component---src-templates-contact-ts": () => import("./../../../src/templates/contact.ts" /* webpackChunkName: "component---src-templates-contact-ts" */),
  "component---src-templates-home-ts": () => import("./../../../src/templates/home.ts" /* webpackChunkName: "component---src-templates-home-ts" */),
  "component---src-templates-news-ts": () => import("./../../../src/templates/news.ts" /* webpackChunkName: "component---src-templates-news-ts" */),
  "component---src-templates-portfolio-ts": () => import("./../../../src/templates/portfolio.ts" /* webpackChunkName: "component---src-templates-portfolio-ts" */),
  "component---src-templates-services-ts": () => import("./../../../src/templates/services.ts" /* webpackChunkName: "component---src-templates-services-ts" */),
  "component---src-templates-team-ts": () => import("./../../../src/templates/team.ts" /* webpackChunkName: "component---src-templates-team-ts" */)
}

